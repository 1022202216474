"use client";

import { useSession } from "next-auth/react";
import { useEffect } from "react";
import "~/styles/globals.css";
import { useRecoilState } from "recoil";
import { refreshSessionCountAtom } from "~/state/atoms";
import { api } from "~/trpc/react";

export const useSessionUpdate = () => {
  const { data: session, update } = useSession();
  const [refreshSessionCount] = useRecoilState(refreshSessionCountAtom);

  const apiGetUser = api.user.getById.useQuery(
    {
      id: session?.user?.id ?? "",
    },
    { enabled: !!session?.user?.id },
  );

  useEffect(() => {
    if (session?.user?.id) {
      void apiGetUser.refetch();
    }
  }, [session?.user?.id, refreshSessionCount]);

  useEffect(() => {
    if (session?.user?.id && apiGetUser.data) {
      void update({ user: apiGetUser.data });
    }
  }, [apiGetUser.data, session?.user?.id]);

  return;
};
