import React from "react";
import BottomNavBarItem from "../../Components/BottomNavBarItem/BottomNavBarItem";

type BottomNavBarProps = {
  bottomNavBarItemProps: Omit<
    React.ComponentProps<typeof BottomNavBarItem>,
    "active"
  >[];
  pathname: string;
};

const BottomNavBarModule = (props: BottomNavBarProps) => {
  return (
    <div className="fixed bottom-0 z-[100] w-full desktop:w-[400px]">
      <div className="flex w-full items-start justify-start bg-white">
        <div className="flex h-fit w-full flex-row items-start justify-evenly">
          {props.bottomNavBarItemProps.map(
            (bottomNavBarItemItemProps, index) => (
              <BottomNavBarItem
                key={index}
                {...bottomNavBarItemItemProps}
                active={
                  !!props.pathname.includes(bottomNavBarItemItemProps.href)
                }
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BottomNavBarModule);
