import { usePathname, useRouter } from "next/navigation";
import { useEffect, type ReactNode } from "react";
import { useRecoilState } from "recoil";
import { useAuth } from "~/hooks/useAuth";
import {
  lastPathnameAtom,
  searchTextAtom,
  showLoaderOverlayAtom,
} from "~/state/atoms";
import { CenterSpinnerAtom } from "../Atoms/CenterSpinnerAtom/CenterSpinnerAtom";
import { LoadingOverlayAtom } from "../Atoms/LoadingOverlayAtom/LoadingOverlayAtom";
import { signOut, useSession } from "next-auth/react";
import { useBalance } from "~/hooks/useBalance";
import { PointBox } from "../Components/PointBox/PointBox";
import { useSessionUpdate } from "~/hooks/useSessionUpdate";
import { useLike } from "~/hooks/useLike";
import { message } from "antd";
import { useBlackList } from "~/hooks/useBlackList";

const AuthGuard: React.FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [searchText, setSearchText] = useRecoilState(searchTextAtom);
  const [lastPathname, setLastPathname] = useRecoilState(lastPathnameAtom);
  const { data: session, update } = useSession();
  const [showLoaderOverlay, setShowLoaderOverlay] = useRecoilState(
    showLoaderOverlayAtom,
  );
  const idBlackList = useBlackList();

  void useAuth();
  void useSessionUpdate();
  void useLike();
  const { pointBalance } = useBalance();

  const publicPages = ["/login", "/login/callback"];

  useEffect(() => {
    if (showLoaderOverlay !== false) {
      const timer = setTimeout(
        () => {
          setShowLoaderOverlay(false);
        },
        showLoaderOverlay === true ? 3000 : showLoaderOverlay,
      );

      return () => clearTimeout(timer);
    }
  }, [showLoaderOverlay]);

  useEffect(() => {
    if (session === undefined) {
      return;
    }

    if (session?.user?.id && idBlackList.includes(session?.user?.id)) {
      void message.error(
        "Your Account have been blocked by the administrator.",
      );
      return void signOut();
    }

    if (session?.user === undefined && !publicPages.includes(pathname ?? "")) {
      router.replace("/login");
    }
  }, [session, pathname]);

  useEffect(() => {
    void update();
    setShowLoaderOverlay(false);
    setSearchText("");
    setLastPathname(pathname ?? "");
  }, [pathname]);

  return (
    <>
      {session === undefined ||
      (session?.user === undefined && !publicPages.includes(pathname ?? "")) ? (
        <CenterSpinnerAtom />
      ) : (
        children
      )}
      {showLoaderOverlay && <LoadingOverlayAtom />}
      {/* <PointBox balance={pointBalance} /> */}
    </>
  );
};

export default AuthGuard;
