"use client";

import "~/styles/globals.css";
import { RecoilRoot } from "recoil";
import { TRPCReactProvider } from "~/trpc/react";
import { usePathname } from "next/navigation";
import { RootStyleRegistry } from "~/components/Atoms/RootStyleRegistry";
import BottomNavBarModule from "~/components/Modules/BottomNavBar/BottomNavBarModule";
import { SessionProvider } from "next-auth/react";
import { Layout } from "antd";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import { Web3ModalProvider } from "~/components/Auth/Web3Modal";
import LeftSectionTemplate from "~/components/Templates/LeftSectionTemplate";
import AuthGuard from "~/components/Auth/AuthGuard";

export default function GlobalLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const hasBottomNavBar = ![
    "/login",
    "/terms",
    "/selectCountry",
    "/setNickname",
    "/myFandomSetting",
    "/joinFandom",
    "/send",
    "/swap",
    "/pointHistory",
    "/setting",
    "/txDetail",
    /^\/fandom\/\d+$/,
    /^\/exitClub\/\d+$/,
    /^\/clubJoin\/\d+$/,
    /^\/addStake\/\d+$/,
    /^\/terms\/\w+$/,
    /^\/vote\/\d+$/,
    /^\/voteReg\/\d+$/,
    /^\/feed\/\d+$/,
    /^\/feedReg\/\d+\/\d+$/,
    /^\/commentDetail\/\d+$/,
    /^\/vote\/\d+\/comment+$/,
  ].some((path) => {
    if (typeof path === "string") {
      return pathname === path;
    } else if (path instanceof RegExp) {
      return path.test(pathname ?? "");
    }
  });

  useEffect(() => {
    hotjar.initialize(3830792, 6);

    // 카카오톡 인앱브라우져에서 web3auth 로그인이 안됨
    // 기본 브라우져로 여는 코드 추가
    const userAgent = window.navigator.userAgent.toLowerCase();
    const targetUrl = window.location.href;

    if (userAgent.match(/kakaotalk/i)) {
      window.location.href =
        "kakaotalk://web/openExternal?url=" + encodeURIComponent(targetUrl);
      return;
    }
  }, []);

  const BottomNavBarProps: React.ComponentProps<typeof BottomNavBarModule> = {
    bottomNavBarItemProps: bottomNavBarItemProps,
    pathname: pathname ?? "",
  };

  return (
    <RootStyleRegistry>
      <SessionProvider>
        <RecoilRoot>
          <Web3ModalProvider>
            <TRPCReactProvider cookies="">
              {/* Full Section */}
              <div className="relative flex h-[100vh] flex-row justify-center border-0">
                {/* Left Section */}
                <div className="sticky top-0 hidden h-fit w-[400px]  desktop:block">
                  <LeftSectionTemplate />
                </div>

                {/* Right Section */}
                <div className="flex h-full w-full flex-col desktop:w-[400px]">
                  {/* Shadow */}
                  <div className="fixed z-[-1] h-full w-full flex-col shadow-custom desktop:w-[400px]" />

                  {/* Content */}
                  <Layout>
                    <div
                      className={`${
                        hasBottomNavBar ? "h-[calc(100vh-67px)]" : "h-[100vh]"
                      } pt-[54px]`}
                    >
                      {/* 페이지 접근권한과 Recoil 기능 활용을 위한 컴포넌트 */}
                      <AuthGuard>{children}</AuthGuard>
                    </div>

                    {/* Bottom */}
                    {hasBottomNavBar && (
                      <BottomNavBarModule {...BottomNavBarProps} />
                    )}
                  </Layout>
                </div>
              </div>
            </TRPCReactProvider>
          </Web3ModalProvider>
        </RecoilRoot>
      </SessionProvider>
    </RootStyleRegistry>
  );
}

const bottomNavBarItemProps: React.ComponentProps<
  typeof BottomNavBarModule
>["bottomNavBarItemProps"] = [
  {
    title: "Home",
    iconProps: { iconName: "home", width: 24, height: 24 },
    href: "/home",
  },
  {
    title: "Fandom",
    iconProps: { iconName: "crown", width: 24, height: 24 },
    href: "/fandom",
  },
  {
    title: "Vote",
    iconProps: { iconName: "vote", width: 24, height: 24 },
    href: "/vote",
  },
  {
    title: "My",
    iconProps: { iconName: "defaultAvatar", width: 24, height: 24 },
    href: "/myPage",
  },
];
