import Link from "next/link";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";

type BottomNavBarItemProps = {
  title: string;
  iconProps: React.ComponentProps<typeof SVGAtom>;
  active: boolean;
  href: string;
};

const BottomNavBarItem = (props: BottomNavBarItemProps) => {
  return (
    <div className="flex w-full justify-center border-t-[1px] border-t-[rgba(0,0,0,0.06)]">
      <Link
        href={props.href}
        className="flex max-w-[75px] flex-[1_0_0] flex-col items-center gap-[2px]  p-[10px_0px]"
      >
        <div className="h-[24px] w-[24px]">
          <SVGAtom
            {...props.iconProps}
            iconName={
              (props.iconProps.iconName +
                (props.active ? "Clicked" : "")) as any
            }
          />
        </div>
        <div
          className={`w-[56px] text-center text-[10px] font-semibold leading-[20px] ${
            props.active && "text-blue"
          }`}
        >
          {props.title}
        </div>
      </Link>
    </div>
  );
};

export default BottomNavBarItem;
