import LogoAtom from "../Atoms/LogoAtom/LogoAtom";

const LeftSectionTemplate = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-[15px] px-[25px]">
      <div className="fixed top-[50%]">
        <LogoAtom isLinkOff={true} />
      </div>
    </div>
  );
};

export default LeftSectionTemplate;
