import Spin from "antd/es/spin";
import cn from "classnames";

type Props = {
  className?: string;
  type?: "MORE";
};

export const CenterSpinnerAtom = (props: Props) => {
  const defaultClass = "flex h-full w-full items-center justify-center";

  return props.type === "MORE" ? (
    <>
      <div
        className={cn(
          "flex h-[150px] w-full items-center justify-center bg-[#f5f5f5]",
          props.className,
        )}
      >
        <Spin />
      </div>
      <div className="h-[100px] bg-[#f5f5f5]"></div>
    </>
  ) : (
    <div className={cn(defaultClass, props.className)}>
      <div className="lds-logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="101"
          height="72"
          viewBox="0 0 101 72"
          fill="none"
        >
          <g clipPath="url(#clip0_1337_4359)">
            <path
              d="M46.2089 17.3243C41.9972 21.4622 38.1651 25.1784 34.3909 28.9631C32.8464 30.5128 32.7568 32.2313 34.3804 33.8232C35.9459 35.3571 37.6116 35.4467 39.2562 33.9022C39.7675 33.4225 40.2525 32.9112 40.748 32.4158C50.0358 23.0805 59.2446 13.6661 68.6853 4.48892C70.5408 2.68089 73.1553 1.34728 75.6591 0.577684C80.5983 -0.92988 85.0366 0.69365 88.737 4.13575C91.4991 6.70282 94.1347 9.41749 96.7281 12.1585C102.247 17.9937 102.268 26.317 96.5858 32.052C84.7836 43.9597 72.9076 55.8041 60.9683 67.5747C54.9855 73.4731 46.6623 73.4521 40.7163 67.5747C28.7771 55.7883 16.901 43.9544 5.09355 32.052C-0.546633 26.3697 -0.53609 17.941 4.96705 12.1532C7.56575 9.41749 10.2224 6.72391 12.9687 4.14102C18.825 -1.37266 27.1219 -1.30941 32.8675 4.24644C37.2163 8.45286 41.507 12.712 46.2089 17.3296V17.3243Z"
              fill="#2F54EB"
            />
          </g>
          <defs>
            <clipPath id="clip0_1337_4359">
              <rect
                width="100"
                height="71.973"
                fill="white"
                transform="translate(0.850586 0.0134277)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};
