import { CenterSpinnerAtom } from "../CenterSpinnerAtom/CenterSpinnerAtom";

type Props = {
  //
};

export const LoadingOverlayAtom = (props: Props) => {
  return (
    <div
      className="fixed inset-0 z-[1000] flex items-center justify-center"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="pointer-events-auto">
        <CenterSpinnerAtom />
      </div>
    </div>
  );
};
