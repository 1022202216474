"use client";

import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import "~/styles/globals.css";
import { useRecoilState } from "recoil";
import { likedCommentIdsAtom, likedFeedIdsAtom } from "~/state/atoms";
import { api } from "~/trpc/react";

export const useLike = () => {
  const { data: session } = useSession();

  // likedFeedIds
  const [likedFeedIds, setLikedFeedIds] = useRecoilState(likedFeedIdsAtom);

  const apiGetMyLikeFeedIds = api.feed.getMyLikeFeedIds.useQuery(
    { userId: session?.user?.id ?? "" },
    { enabled: !!session?.user?.id },
  );

  useEffect(() => {
    if (apiGetMyLikeFeedIds.data) {
      setLikedFeedIds(apiGetMyLikeFeedIds.data);
    }
  }, [apiGetMyLikeFeedIds.data]);

  // likedCommentIds
  const [likedCommentIds, setLikedCommentIds] =
    useRecoilState(likedCommentIdsAtom);

  const apiGetMyLikeCommentIds = api.comment.getMyLikeCommentIds.useQuery(
    { userId: session?.user?.id ?? "" },
    {
      enabled: !!session?.user?.id,
    },
  );

  useEffect(() => {
    if (apiGetMyLikeCommentIds.data) {
      setLikedCommentIds(apiGetMyLikeCommentIds.data);
    }
  }, [apiGetMyLikeCommentIds.data]);

  return;
};
