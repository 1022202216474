"use client";

import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import "~/styles/globals.css";
import { useRecoilState } from "recoil";
import { pointBalanceAtom, refreshBalanceCountAtom } from "~/state/atoms";
import { api } from "~/trpc/react";
import Decimal from "decimal.js";

export const useBalance = () => {
  const { data: session } = useSession();
  const [pointBalance, setPointBalance] = useRecoilState(pointBalanceAtom);
  const [refreshBalanceCount] = useRecoilState(refreshBalanceCountAtom);

  const apiGetPointBalance = api.point.getBalance.useQuery(
    {
      userId: session?.user?.id ?? "",
    },
    { enabled: !!session?.user?.id },
  );

  const refreshPointBalance = () => {
    if (session?.user?.id) {
      void apiGetPointBalance.refetch();
    }
  };

  useEffect(() => {
    refreshPointBalance();
  }, [refreshBalanceCount]);

  useEffect(() => {
    if (apiGetPointBalance.data) {
      setPointBalance(new Decimal(apiGetPointBalance.data ?? 0));
    }
  }, [apiGetPointBalance.data]);

  return { pointBalance, refreshPointBalance };
};
